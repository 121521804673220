/* Pretty printing styles. Used with prettify.js. */

/* SPAN elements with the classes below are added by prettyprint. */

.pln {
  color: #000; }

/* plain text */

@media screen {
  .str {
    color: #080; }
  /* string content */
  .kwd {
    color: #008; }
  /* a keyword */
  .com {
    color: #800; }
  /* a comment */
  .typ {
    color: #606; }
  /* a type name */
  .lit {
    color: #066; }
  /* a literal value */
  /* punctuation, lisp open bracket, lisp close bracket */
  .pun, .opn, .clo {
    color: #660; }
  .tag {
    color: #008; }
  /* a markup tag name */
  .atn {
    color: #606; }
  /* a markup attribute name */
  .atv {
    color: #080; }
  /* a markup attribute value */
  .dec, .var {
    color: #606; }
  /* a declaration; a variable name */
  .fun {
    color: red; }
  /* a function name */ }

/* Use higher contrast and text-weight for printable form. */
@media print, projection {
  .str {
    color: #060; }
  .kwd {
    color: #006;
    font-weight: bold; }
  .com {
    color: #600;
    font-style: italic; }
  .typ {
    color: #404;
    font-weight: bold; }
  .lit {
    color: #044; }
  .pun, .opn, .clo {
    color: #440; }
  .tag {
    color: #006;
    font-weight: bold; }
  .atn {
    color: #404; }
  .atv {
    color: #060; } }

/* Put a border around prettyprinted code snippets. */

pre.prettyprint {
  padding: 2px;
  border: 1px solid #888; }

/* Specify class=linenums on a pre to get line numbering */

ol.linenums {
  margin-top: 0;
  margin-bottom: 0; }

/* IE indents via margin-left */

li {
  &.L0, &.L1, &.L2, &.L3, &.L5, &.L6, &.L7, &.L8 {
    list-style-type: none; }
  &.L1, &.L3, &.L5, &.L7, &.L9 {
    background: #eee; } }

/* Alternate shading for lines */
