@import "base";
@import "prettify";

*, *:before, *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

$small-width: 600px;

/* typography */

body {
  font-size: 18px;
  @media(max-width: #{$small-width}) {
    font-size: 18px;
    line-height: 1.4; } }

p {
  line-height: 1.35em; }

h1,h2,h3,h4,h5 {
  font-weight: 300;
  margin-top: 0; }

body, input[type="text"], input[type="submit"] {
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 300; }

.body {
  /* ensure wrapping of long lines etc */
  overflow: hidden; }

.body h1 {
  font-size: 2em;
  line-height: 1.25em; }

.sub_title {
  font-size: .4em; }

/* typographical layout */

.comment {
  position: relative;
  margin: 20px auto; }

.comment .comment-heading {
  height: 50px;
  font-size: 14px;

  display: flex;

  img {
    object-fit: cover;
    border-radius: 50%;
    height: 32px;
    width: 32px; } }

.article-footer {
  border-top: 1px solid #bbb; }

.comment-info {
  display: flex;
  flex-direction: column;

  margin-left: .5em; }

.comment-author {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline; } }

.comment-info {
  color: rgba(0, 0, 0, 0.5); }


.comment-help {
  text-align: right;
  color: rgba(0, 0, 0, 0.5);
  a {
    font-style: italic; } }

.comment-body {
  p:first-child {
    margin-top: 0; } }

@mixin reading_gutter {
  width: 42rem;
  margin: 0 auto;
  padding: 0 0.5rem;
  @media(max-width: #{$small-width}) {
    width: auto;
    padding: 0 1rem;
    overflow: visible; } }


.footer, .content, .reading, .comments {
  @include reading_gutter; }


h1 {
  margin: .5rem 0 1.5rem 0;
  @media(max-width: #{$small-width}) {
    margin: 0 0 .25rem 0; } }


/* links */
a {
  color: #FF7C14;
  text-decoration: none;
  &:link, &:visited {
    color: #FF7C14;
    text-decoration: none; }
  &:hover, &:active, &:focus {
    text-decoration: underline; } }

h1, h2, h3, h4 {
  a {
    color: #000;
    &:active, &:visited, &:hover, &:link, &:focus {
      color: #000;
      text-decoration: none; } } }

// summary at top of article
.summary {
  padding: 1em 1em;
  background: #FFF7F7;
  margin: 0 0 1em 0;
  h3 {
    margin: 0 0 1em 0; } }

ul ul {
  padding: 1em; }

blockquote {
  margin: 1em 0;
  padding: 0 0 0 0.75em;
  border-left: 2px solid #00c0ff;
  font-size: 0.9em; }


/* layout */

.mast {
  width: 100%;
  padding: .6em 0 0 0;
  a {
    color: #888;
    -webkit-transition: all .4s; }
  a:hover {
    color: #222; }
  .chapters {
    font-size: .8em; } }

.top-bar {
  overflow: auto;
  .author {
    display: block;
    float: right; } }

.footer {
  clear: both; }

.sub {
  font-size: 0.7em;
  font-style: italic; }

.minor {
  font-size: 0.8em; }
.break {
  margin: 0 0 0 1em; }

.logical {
  display: inline; }



.mast {
  background: url(../img/mast.png) 0 0 repeat-x;
  a {
    padding: .15em .3em;
    &:first-child {
      padding-left: 0; } }
  ul {
    padding: 0;
    margin: 0;
    li {
      list-style-type: none;
      float: left;
      margin: 0 .5em; } } }


.root .sub_title {
  display: block; }



// code formatting
pre {
  font-size: 1rem;
  margin-bottom: 1rem;
  white-space: pre-wrap;
  @media (min-width: #{$small-width}) {
    font-size: 1em;
    padding: 0.5em 1em; } }

.body code, .body code[class*="language-"] {
  white-space: pre-wrap; }

.plug {
  border: 1px solid hsl(223, 100%, 84%);
  padding: 0.25rem 0.5rem;
  margin-top: 4ex;
  @media (min-width: #{$small-width}) {
    padding: 0.5rem 1rem; } }


.comments {
  h3 {
    margin-top: 0.5em; }
  input[type=text] {
    width: 95%;
    padding: .25em; }
  input[type=text], textarea {
    font-size: 1em; }
  textarea {
    width: 100%; } }

.posts {
  h4 {
    margin: 0;
    margin-right: 1em; }
  a[href] {
    color: #000;
    text-decoration: none; }
  .date {
    font-size: 0.7em;
    color: #aaa; }
  .post {
    display: block;
    @include reading_gutter;
    padding-top: .5em;
    padding-bottom: .5em;
    border-left: .25rem solid #00c0ff;
    &:hover {
      background: #00c0ff;
      color: #fff;
      .date {
        color: #fff; } }
    padding-left: .25rem;
    margin-bottom: 6px;
    @media (min-width: #{$small-width}) {
      border-left: 6px solid #00c0ff;
      padding-left: .7rem; } }
  .post:nth-child(2n) {
    border-color: #ff7c14;
    &:hover {
      background: #ff7c14; } }
  .post:nth-child(3n), .post:nth-child(4n) {
    &:hover {
      color: #000;
      .date {
        color: #000; } } }
  .post:nth-child(3n) {
    border-color: #FFFF06;
    &:hover {
      background: #FFFF06; } }
  .post:nth-child(4n) {
    border-color: #a7ff2a;
    &:hover {
      background: #a7ff2a; } } }

// not yet tested
//table
//  td:first-child
//    border-left: none
//  td:last-child
//    border-right: none
//  tr:first-child td
//    border-top: none
//  tr:last-child td
//    border-bottom: none
//  border: 1px solid #eeeeee

// one off
.post_mortem, .key {
  li {
    list-style-type: none; }
  .why {
    padding-left: 2em; }
  .yes {
    background: #FFF7ED; }
  .no {
    color: #444; }
  .na {
    background: #E9E9E9; } }

// one off for story boxes
.story {
  padding: 1em;
  overflow: auto;
  margin-bottom: 1em;
  p {
    float: left;
    width: 24em;
    margin-bottom: 0; } }

.bad {
  background: #F8C6AF; }
.good {
  background: #C0F8AF; }

.smiley {
  font-family: "Helvetica";
  font-size: 4em;
  float: left;
  width: 1em; }

.footnotes {
  font-size: 0.9em;
  color: #333; }

.twitter-tweet {
  width: 100%;
  white-space: normal;
  margin: 0; }

.content {
  img, video, iframe {
    /* scale the image proportionally, but never larger than container */
    max-width: 100%;
    height: auto; } }


blockquote p {
  margin: 0;
  white-space: normal; }
